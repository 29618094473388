import { dynamicSort, dynamicFilter } from "@/helpers/datatable";

import { LOCALSTORAGE_EVENTS_LIST, LOCALSTORAGE_CURRENT_EVENT, LOCALSTORAGE_USERS_LIST, LOCALSTORAGE_CURRENT_USER } from "@Utils/constants";
import { getUsers } from "@API/user/user-repository";
import { getEvents, getCustomers } from "@API/event/event-repository";
import { getEventTemplates } from "@API/event/template-repository";
import { getPolling } from "@API/event/polling-repository";
import { getOrders } from "@API/event/order-repository";
import { orderBy } from "lodash";

export function useTableData() {
  const fetchEventsListDataTable = async function (filterText, sortStr) {
    const result = await getEvents();
    const filteredArr = result.success ? dynamicFilter(result.data, "name", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  const fetchEventTemplatesListDataTable = async function (filterText, sortStr) {
    const result = await getEventTemplates();
    const filteredArr = result.success ? dynamicFilter(result.data, "name", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  const fetchUsersListDatatable = async function (filterText, sortStr) {
    const result = await getUsers();
    const filteredArr = result.success ? dynamicFilter(result.data, "fullName", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  const fetchCustomersListDatatable = async function (eventId, filterText, sortStr) {
    const result = await getCustomers(eventId);
    const filteredArr = result.success ? dynamicFilter(result.data, "email", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  const fetchPollingListDatatable = async function (eventId, filterText, sortStr) {
    const result = await getPolling(eventId);
    const filteredArr = result.success ? dynamicFilter(result.data, "name", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  const fetchOrderListDatatable = async function (eventId, filterText, sortStr) {
    const result = await getOrders(eventId);
    const dataSortedByLastUpdate = orderBy(result.data, ["lastUpdatedDate"], ["desc"]);
    const filteredArr = result.success ? dynamicFilter(dataSortedByLastUpdate, "status", filterText) : [];

    return filteredArr.length ? filteredArr.sort(dynamicSort(sortStr)) : [];
  };

  return {
    fetchEventsListDataTable,
    fetchUsersListDatatable,
    fetchPollingListDatatable,
    fetchCustomersListDatatable,
    fetchOrderListDatatable,
    fetchEventTemplatesListDataTable,
  };
}

export const setLocalstorageEventsList = events => {
  localStorage.setItem(LOCALSTORAGE_EVENTS_LIST, JSON.stringify(events));
};

export const getLocalstorageEventsList = () => {
  try {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_EVENTS_LIST));
  } catch (_) {
    return null;
  }
};

export const hasLocalstorageEventsList = () => {
  let events = getLocalstorageEventsList();
  return !!events;
};

export const setLocalstorageCurrentEvent = event => {
  localStorage.setItem(LOCALSTORAGE_CURRENT_EVENT, JSON.stringify(event));
};

export const getLocalstorageCurrentEvent = () => {
  try {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_EVENT));
  } catch (_) {
    return null;
  }
};

export const hasLocalstorageCurrentEvent = () => {
  let event = getLocalstorageCurrentEvent();
  return !!event;
};

export const setLocalstorageUsersList = users => {
  localStorage.setItem(LOCALSTORAGE_USERS_LIST, JSON.stringify(users));
};

export const getLocalstorageUsersList = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_USERS_LIST));
};

export const hasLocalstorageUsersList = () => {
  let users = getLocalstorageUsersList();
  return !!users;
};

export const setLocalstorageCurrentUser = user => {
  localStorage.setItem(LOCALSTORAGE_CURRENT_USER, JSON.stringify(user));
};

export const getLocalstorageCurrentUser = () => {
  return JSON.parse(localStorage.getItem(LOCALSTORAGE_CURRENT_USER));
};

export const hasLocalstorageCurrentUser = () => {
  let user = getLocalstorageCurrentUser();
  return !!user;
};
