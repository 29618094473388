import Repository from "@API/repository";

export const getOrders = async eventId => {
  const url = `event/${eventId}/order`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};
