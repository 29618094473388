import Repository from "@API/repository";

export const getPolling = async eventId => {
  const url = `event/${eventId}/poll`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getPollingAnswer = async (eventId, pollId) => {
  const url = `event/${eventId}/poll/${pollId}/answer`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getPollingReport = async (eventId, pollId) => {
  const url = `/event/${eventId}/poll/${pollId}/report`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const doCreatePoll = async (eventId, data) => {
  const url = `event/${eventId}/poll`;
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const doUpdatePoll = async (eventId, pollId, data) => {
  const url = `event/${eventId}/poll/${pollId}`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const doDeletePoll = async (eventId, pollId) => {
  const url = `event/${eventId}/poll/${pollId}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};
