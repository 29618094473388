import { isString, isNumber, isUndefined, lowerCase, filter, keys, get, includes, some } from "lodash";
import { empty } from "@/utils";
export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function dynamicFilter(arr, property, searchText) {
  if (!searchText.length) {
    return arr;
  } else {
    return arr.filter(item => item[property].toLowerCase().includes(searchText.toLowerCase()));
  }
}

export function dynamicPaginate(data, currentPage, perPage) {
  const from = currentPage * perPage - perPage;
  const to = currentPage * perPage;
  return data.slice(from, to);
}

export function slugData(data, separator) {
  if (isString(data)) {
    let slug = lowerCase(data);
    // convert to english type
    slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, "a");
    slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, "e");
    slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, "i");
    slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, "o");
    slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, "u");
    slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, "y");
    slug = slug.replace(/đ/gi, "d");
    if (isString(separator)) {
      slug = slug.replace(/[^a-zA-Z0-9]/gi, separator);
    } else {
      slug = slug.replace(/[^a-zA-Z0-9]/gi, "");
    }
    return slug;
  }
  return data;
}

export function filterDataCustom(search, dataSource, attributes) {
  if (!isNumber(search) && !isString(search)) {
    return dataSource;
  }
  const searchVal = slugData(search);
  return filter(dataSource, item => {
    if (isString(item)) {
      return includes(slugData(item), searchVal);
    }
    if (empty(attributes) || isUndefined(attributes)) {
      attributes = keys(item);
    }
    return some(attributes, att => {
      let data = get(item, att);
      if (!isString(data)) {
        data = JSON.stringify(data);
      }
      return includes(slugData(data), searchVal);
    });
  });
}
