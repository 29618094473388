<template>
  <div class="relative" v-outside="toggleDropdown">
    <button
      id="options-menu"
      type="button"
      aria-haspopup="true"
      aria-expanded="true"
      class="
        inline-flex
        justify-between
        items-center
        w-full
        appearance-none
        bg-off-white-1
        dark:bg-dark-bgPrimary
        text-xs
        md:text-lg
        text-black-1
        dark:text-dark-textPrimary
        border border-grey-mid
        focus:border-none focus:outline-none focus:ring-2 focus:ring-magenta
        rounded
        py-2
        md:py-3
        px-2
        md:px-4
      "
      @click="toggleDropdown(false)"
    >
      <span v-text="labelCombobox" />
      <ArrowDownIcon class="-mr-1 ml-2 h-5 w-5" />
    </button>

    <div
      ref="dropdown"
      class="
        z-10
        origin-top-right
        absolute
        right-0
        mt-2
        w-full
        rounded
        shadow-lg
        bg-white
        dark:bg-dark-bgSecondary
        ring-1 ring-grey-mid
        transform
        hidden
        opacity-0
        z-10
      "
    >
      <div
        class="py-1 overflow-y-auto"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        :style="{
          maxHeight: '300px',
        }"
      >
        <a
          v-for="(option, index) in options"
          :key="index"
          href="#"
          class="
            flex
            items-center
            justify-between
            text-xs
            md:text-lg
            hover:bg-off-white-1
            dark:hover:bg-dark-bgPrimary
            py-2
            md:py-3
            px-2
            md:px-4
          "
          :class="[
            (isString(modelValue) ? modelValue : modelValue[keyValue]) ===
            (isString(option) ? option : option[keyValue])
              ? 'text-magenta dark:text-magenta font-bold'
              : 'text-black-1 dark:text-dark-textPrimary',
          ]"
          role="menuitem"
          @click="() => onChangeOption(option)"
        >
          {{ isString(option) ? option : option[labelKey] }}
          <ApproveIcon
            v-if="
              (isString(modelValue) ? modelValue : modelValue[keyValue]) ===
              (isString(option) ? option : option[keyValue])
            "
            class="h-5 w-5 md:h-7 md:w-7 cursor-pointer inline"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { isString, get, forEach, isObject } from "lodash";

import { TweenMax } from "gsap";
import ArrowDownIcon from "@Assets/images/ArrowDownIcon";
import ApproveIcon from "@Assets/images/ApproveIcon";
export default {
  components: {
    ArrowDownIcon,
    ApproveIcon,
  },
  props: {
    modelValue: [String, Object],
    options: Array,
    keyValue: {
      type: String,
      default: "value",
    },
    labelKey: {
      type: String,
      default: "label",
    },
  },
  setup(props, { emit }) {
    let dropdown = ref(null);
    const data = reactive({
      isOpenDropdown: false,
    });
    const labelCombobox = computed(() => {
      let label;
      forEach(props.options, item => {
        if (isString(item) && item === props.modelValue) {
          label = item;
        } else if (
          isObject(item) &&
          item.value ===
            (isString(props.modelValue)
              ? props.modelValue
              : props.modelValue[props.keyValue])
        ) {
          label = item.label;
        }
      });
      return label;
    });
    function toggleDropdown(forceClose = true) {
      data.isOpenDropdown = forceClose;
      TweenMax.to(dropdown.value, {
        scale: data.isOpenDropdown ? 0.95 : 1,
        duration: data.isOpenDropdown ? 0.5 : 0.2,
        ease: data.isOpenDropdown ? "ease-in" : "ease-out",
        display: data.isOpenDropdown ? "none" : "block",
        opacity: data.isOpenDropdown ? 0 : 1,
      });
      data.isOpenDropdown = !data.isOpenDropdown;
    }
    function onChangeOption(option) {
      emit(
        "update:modelValue",
        isString(props.modelValue)
          ? get(option, props.keyValue, option)
          : option,
      );
      toggleDropdown();
    }

    return {
      dropdown,
      toggleDropdown,
      onChangeOption,
      isString,
      labelCombobox,
    };
  },
};
</script>
