import Repository, { repositoryNoHeader } from "@API/repository";

export const getEventTemplates = async () => {
  const url = "event-template";
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getEventTemplate = async payload => {
  const url = `event-template/${payload}`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const postEventTemplate = async data => {
  const url = "event-template";
  const response = await Repository({
    url,
    method: "POST",
    data,
  });
  return response.data;
};

export const putEventTemplate = async data => {
  const url = `event-template/${data.id}`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const deleteEventTemplate = async payload => {
  const url = `event-template/${payload}`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};
